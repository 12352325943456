import React from 'react'
import './hubcard.css'

const HubCard = props => (
    
    <div className="hub-card">
        <div className="hub-card-img">
            <a href={props.url}>
                <img src={props.image} alt={props.alttext}/>
            </a>
        </div>
            <a href={props.url}>
                <h3>{props.title}</h3>
            </a>
            <div className="content-type-text">{props.contenttype}</div>
            <p className="date-time">{props.date}</p>
            <div dangerouslySetInnerHTML={
                {__html: `${props.desc}`}
            } />    
    </div>

)

export default HubCard
