import React from 'react'
import './post-hero.css'

const PostHero = props => (
    
    <div className="post-hero" style={{
        backgroundImage: `linear-gradient(180deg, rgba(28,28,28,1) 0%, rgba(28,28,28,0) 0%, rgba(28,28,28,1) 100%), 
        url(${props.image})`}} 
    >
        <div className="post-hero-group">
            <h1>{props.title}</h1>
            <p>{props.description}</p>
        </div>
    </div>
)


export default PostHero


