import React from 'react'
import './hubcard.css'

const PasteventCard = props => (
    
    <div className="hub-card">
        <div className="hub-card-img">
            <img src={props.image} alt={props.alttext}/>
        </div>
        <h3>{props.title}</h3>
    </div>

)

export default PasteventCard
