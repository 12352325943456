import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import TequilacurrentFeed from "../components/tequilacurrentfeed"
import TequilapasteventsFeed from "../components/tequilapasteventsfeed"

const TequilaHub = () => (

    <Layout>
        <SEO title="Tequila Collection" />
    
        <PostHero
            title="Tequila Collection"
            description="Mexico's blue agave spirit"
            image={require('../images/justin-heap-JT9B67LLjZI-unsplash.jpg')}
        />

      <div className="hub-wrapper">

        <div className="hub-section">

            <div className="hub-cards-section">
                <TequilacurrentFeed />
            </div>

            <div className="hub-section-title center">
                <h2>Recent Tequila Events</h2>
            </div>
            <div className="hub-cards-section">
                <TequilapasteventsFeed />
            </div>

         </div>

      </div>

  </Layout>
)

export default TequilaHub